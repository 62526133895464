import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm$productCustoms$te, _vm$productCustoms$te2, _vm$data$touchOrLineI2, _vm$data$touchOrLineI3, _vm$data$touchOrLineI4, _vm$data$touchOrLineI5, _vm$data$touchOrLineI6, _vm$data$touchOrLineI7;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v("\n          " + _vm._s(_vm.fromOrder ? _vm.$t('action.editingOrder') : _vm.$t('action.creationOrder')) + "\n        ")])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.fromContact && _vm.data.draftOrderId ? _c('div', {
    staticClass: "d-flex flex-column align-end"
  }, [_c('div', {
    staticClass: "d-flex text-caption"
  }, [_c('div', {
    staticClass: "secondary-text--text mr-2"
  }, [_vm._v("\n            " + _vm._s(_vm.$t('contacts.send.draft.lastUpdatedAt')) + "\n          ")]), _vm._v("\n          " + _vm._s(_vm.data.draftOrderUpdatedAt) + "\n        ")]), _vm._v(" "), _c('TextButton', {
    attrs: {
      "value": _vm.$t('action.checkDraft')
    },
    on: {
      "click": _vm.openDraftSelectDialog
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.fromOrder && _vm.data.draftOrderId ? _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    on: {
      "click": _vm.sendDraft
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('action.sendDraft')) + "\n      ")]) : _vm._e()], 1), _vm._v(" "), _c('ContentArea', {
    attrs: {
      "set": _vm.labelNumber = 1
    }
  }, [_c('Loading', {
    attrs: {
      "modal": "",
      "value": _vm.loading.order || _vm.loading.save || _vm.loading.send
    }
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('destination')) + "\n      ")]), _vm._v(" "), _c('ShippingInfoArea', {
    class: {
      disabled: !_vm.step0
    },
    attrs: {
      "dummy": _vm.loading.contact,
      "contact": _vm.data.contact,
      "salutation": _vm.data.salutation,
      "editable": "",
      "no-sync-status-detail": ""
    },
    on: {
      "update:salutation": function updateSalutation($event) {
        return _vm.$set(_vm.data, "salutation", $event);
      }
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.fromContact ? _vm.labelNumber++ : null,
      "color": _vm.fromContact && _vm.step1 ? 'primary' : null
    }
  }, [_vm._v("\n        " + _vm._s(_vm.isEdit ? _vm.$t('delivery') : _vm.$t('action.selectionTouch')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _vm.isEdit ? _c('VerticalTable', {
    staticClass: "mt-4",
    attrs: {
      "headers": [{
        text: _vm.$t('touch'),
        value: 'touch'
      }],
      "item": _vm.data.touchOrLineItem
    },
    scopedSlots: _vm._u([{
      key: "item.touch",
      fn: function fn() {
        var _vm$data$touchOrLineI;
        return [_c('TouchSummary', {
          staticClass: "py-2",
          attrs: {
            "item": (_vm$data$touchOrLineI = _vm.data.touchOrLineItem) !== null && _vm$data$touchOrLineI !== void 0 ? _vm$data$touchOrLineI : {
              id: '',
              name: ''
            },
            "dummy": !_vm.data.touchOrLineItem
          }
        })];
      },
      proxy: true
    }], null, false, 1520924783)
  }) : _c('TouchItems', {
    attrs: {
      "selectable": "",
      "type": _vm.type,
      "filters": _vm.filterOptions,
      "options": _vm.tableOptions,
      "value": _vm.data.touchOrLineItem
    },
    on: {
      "update:type": function updateType($event) {
        _vm.type = $event;
      },
      "update:filters": function updateFilters($event) {
        _vm.filterOptions = $event;
      },
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "update:value": _vm.onClickItem
    }
  })], 1), _vm._v(" "), !_vm.isDigitalGift ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.shippingAddress,
      "initial-value": _vm.initialFormData.shippingAddress
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "shippingAddress", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.fromContact ? _vm.labelNumber++ : null,
      "color": _vm.fromContact && _vm.step2 ? 'primary' : null
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('action.selectionDestinationAddress')) + "\n          ")]), _vm._v(" "), _vm.selectableAddresses.length ? _c('Input', {
    attrs: {
      "required": "",
      "type": "select",
      "items": _vm.selectableAddresses,
      "item-text": "label",
      "item-value": "id",
      "return-object": "",
      "placeholder": _vm.$t('placeholder.shippingAddress')
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('AddressLabel', {
          attrs: {
            "detail": "",
            "alert-color": "warning",
            "address": item
          }
        })];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var _vm$data$shippingAddr;
        var item = _ref2.item,
          attrs = _ref2.attrs;
        return [_c('AddressLabel', {
          attrs: {
            "detail": "",
            "address": item,
            "active-class": attrs.activeClass,
            "selected": ((_vm$data$shippingAddr = _vm.data.shippingAddress) === null || _vm$data$shippingAddr === void 0 ? void 0 : _vm$data$shippingAddr.id) === item.id
          }
        })];
      }
    }], null, false, 3613307797),
    model: {
      value: _vm.data.shippingAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "shippingAddress", $$v);
      },
      expression: "data.shippingAddress"
    }
  }) : _c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "size": "24",
      "color": "warning"
    }
  }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
    staticClass: "text-body-1 warning--text"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('warning.noAddresses')) + "\n            ")])], 1)], 1), _vm._v(" "), _c('AddressAlert', {
    staticClass: "mt-2",
    attrs: {
      "address": _vm.data.shippingAddress,
      "select-mode": "",
      "empty": !_vm.selectableAddresses.length
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": {
        inhouseDate: _vm.data.inhouseDate,
        shippingMethod: _vm.data.shippingMethod
      },
      "initial-value": {
        inhouseDate: _vm.initialFormData.inhouseDate,
        shippingMethod: _vm.initialFormData.shippingMethod
      },
      "disabled": _vm.isEmpty(_vm.initialFormData)
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.data.inhouseDate = $event.inhouseDate;
        _vm.data.shippingMethod = $event.shippingMethod;
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.fromContact ? _vm.labelNumber++ : null,
      "color": _vm.fromContact && _vm.step3 ? 'primary' : null
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('shipping.selectDesiredArrivalDate')) + "\n            "), _c('IconButton', {
    attrs: {
      "tooltip": _vm.$t('shipping.desiredArrivalDatePrecautions'),
      "icon": "mdi-alert"
    },
    on: {
      "click": _vm.$openDesiredArrivalDatePrecautions
    }
  })], 1), _vm._v(" "), _c('ShippingDateSelect', {
    attrs: {
      "shipping-date": _vm.data.inhouseDate,
      "shipping-method": _vm.data.shippingMethod
    },
    on: {
      "update:shippingDate": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shipping-date": function updateShippingDate($event) {
        return _vm.$set(_vm.data, "inhouseDate", $event);
      },
      "update:shippingMethod": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      },
      "update:shipping-method": function updateShippingMethod($event) {
        return _vm.$set(_vm.data, "shippingMethod", $event);
      },
      "update:error": function updateError($event) {
        _vm.hasDateError = $event;
      }
    }
  })], 1)] : _vm._e(), _vm._v(" "), ((_vm$productCustoms$te = _vm.productCustoms.texts) === null || _vm$productCustoms$te === void 0 ? void 0 : _vm$productCustoms$te.length) > 0 || _vm.productCustoms.url ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('div', {
    staticClass: "p-relative"
  }, [_c('Loading', {
    attrs: {
      "value": _vm.loading.product
    }
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.customTexts,
      "initial-value": _vm.initialFormData.customTexts
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "customTexts", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.fromContact ? _vm.labelNumber++ : null,
      "color": _vm.fromContact && _vm.step4 ? 'primary' : null
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('shipping.customizePrintedText')) + "\n            ")]), _vm._v(" "), ((_vm$productCustoms$te2 = _vm.productCustoms.texts) === null || _vm$productCustoms$te2 === void 0 ? void 0 : _vm$productCustoms$te2.length) > 0 ? _c('TouchTextViewer', {
    key: "svg-".concat((_vm$data$touchOrLineI2 = _vm.data.touchOrLineItem) === null || _vm$data$touchOrLineI2 === void 0 ? void 0 : _vm$data$touchOrLineI2.id),
    attrs: {
      "svg": _vm.data.svg,
      "shipping-targets": [{
        contact: _vm.complementedContact,
        shippingAddress: _vm.data.shippingAddress
      }],
      "qr-url": _vm.data.url,
      "logo": _vm.company.image,
      "customs-texts": _vm.productCustoms.texts,
      "edit": "",
      "required": "",
      "check-placeholder": ""
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasTextError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.customTexts,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "customTexts", $$v);
      },
      expression: "data.customTexts"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.url,
      "initial-value": _vm.initialFormData.url
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "url", $event);
      }
    }
  }, [_vm.productCustoms.url ? _c('Input', {
    key: "url-".concat((_vm$data$touchOrLineI3 = _vm.data.touchOrLineItem) === null || _vm$data$touchOrLineI3 === void 0 ? void 0 : _vm$data$touchOrLineI3.id),
    staticClass: "mt-6",
    attrs: {
      "label": _vm.$t('qrUrl'),
      "required": "",
      "rules": [function (v) {
        return _vm.$isValidUrl(v) || _vm.$t('error.url');
      }]
    },
    model: {
      value: _vm.data.url,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "url", $$v);
      },
      expression: "data.url"
    }
  }) : _vm._e()], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.hasGiftProduct ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _vm.data.lp,
      "initial-value": _vm.initialFormData.lp,
      "disabled": _vm.isEmpty(_vm.initialFormData.lp),
      "keys": ['sender', 'message', 'cta_text', 'cta_link']
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.data, "lp", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.fromContact ? _vm.labelNumber++ : null,
      "color": _vm.fromContact && _vm.step5 ? 'primary' : null
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('shipping.customizeGiftPage')) + "\n          ")]), _vm._v(" "), _c('GiftPageEditor', {
    key: "gift-".concat((_vm$data$touchOrLineI4 = _vm.data.touchOrLineItem) === null || _vm$data$touchOrLineI4 === void 0 ? void 0 : _vm$data$touchOrLineI4.id),
    attrs: {
      "edit": "",
      "required": "",
      "check-placeholder": "",
      "logo": _vm.company.image,
      "shipping-targets": [{
        contact: _vm.complementedContact,
        shippingAddress: _vm.data.shippingAddress
      }],
      "product-title": (_vm$data$touchOrLineI5 = _vm.data.touchOrLineItem.shopify_product.display_gift_name) !== null && _vm$data$touchOrLineI5 !== void 0 ? _vm$data$touchOrLineI5 : _vm.data.touchOrLineItem.shopify_product.variant_title,
      "product-image": (_vm$data$touchOrLineI6 = _vm.data.touchOrLineItem.shopify_product.display_gift_image) !== null && _vm$data$touchOrLineI6 !== void 0 ? _vm$data$touchOrLineI6 : (_vm$data$touchOrLineI7 = _vm.data.touchOrLineItem.shopify_product.images) === null || _vm$data$touchOrLineI7 === void 0 ? void 0 : _vm$data$touchOrLineI7[0]
    },
    on: {
      "update:error": function updateError($event) {
        _vm.hasGiftError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _vm.data.lp,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "lp", $$v);
      },
      expression: "data.lp"
    }
  })], 1)] : _vm._e()], 2), _vm._v(" "), _vm.fromContact ? _c('div', {
    staticClass: "tw-flex tw-justify-center"
  }, [_c('div', {
    staticClass: "flex-equality d-flex justify-end align-center"
  }, [_c('TextButton', {
    staticClass: "mr-4",
    attrs: {
      "value": _vm.$t('action.doSaveDraft'),
      "disabled": !_vm.isSaveDraftEnable
    },
    on: {
      "click": function click($event) {
        return _vm.saveOrder( /* isDraft= */true, /* atLeaveCheck= */false);
      }
    }
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doCheckOrder'))
    },
    on: {
      "click": _vm.submit
    }
  }), _vm._v(" "), _c(VSpacer, {
    staticClass: "flex-equality"
  })], 1) : _vm._e(), _vm._v(" "), _vm.fromOrder ? _c('div', {
    staticClass: "tw-flex tw-justify-center"
  }, [_vm.data.draftOrderId ? _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSaveDraftEnable
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doSaveDraft'))
    },
    on: {
      "click": function click($event) {
        return _vm.saveOrder( /* isDraft= */true, /* atLeaveCheck= */false);
      }
    }
  }) : _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSaveEnable
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.save'))
    },
    on: {
      "click": function click($event) {
        return _vm.saveOrder( /* isDraft= */false, /* atLeaveCheck= */false);
      }
    }
  })]) : _vm._e(), _vm._v(" "), _c('SelectDraftOrderDialog', {
    ref: "selectDraftOrderDialog",
    on: {
      "update:selectedOrderId": function updateSelectedOrderId($event) {
        return _vm.loadOrder($event);
      }
    }
  })], 1), _vm._v(" "), _c('TextEditorContextMenu', {
    ref: "textmenu"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };